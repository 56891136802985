<template>
  <Page pageSlug="condition-general-de-vents" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Page from '@/components/shared/Page.vue';

export default defineComponent({
  components: { Page },
});
</script>
